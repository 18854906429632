export type ColorVariant =
  // ポテンシャルクラウドカラー
  | 'potential-cloud'
  // ポテンシャルクラウドカラー：ホバー
  // | 'hover__potential-cloud'
  // ベース
  | 'link-move'
  | 'link-potential'
  | 'link-excellent'
  | 'link-negative'
  | 'success'
  | 'alert'
  | 'soft__link-move'
  | 'soft__link-potential'
  | 'soft__link-excellent'
  | 'soft__link-negative'
  | 'soft__success'
  | 'soft__alert'
  | 'outlined__link-move'
  | 'outlined__link-potential'
  | 'outlined__link-excellent'
  | 'outlined__link-negative'
  | 'outlined__success'
  | 'outlined__alert'
  // サーベイステータス
  | 'survey-planning' // 準備中
  | 'survey-prepared' // 配信開始待ち
  | 'survey-progress' // 回答受付中
  | 'survey-closed' // 回答受付終了
  | 'survey-canceled' // キャンセル
  // バッジ ユニークカラー
  | 'badge-blue'
  | 'badge-red'
  | 'badge-purple'
  | 'badge-gray'
  | 'badge-light-gray'
  | 'badge-yellow'
  | 'badge-green'
  | 'soft__badge-blue'
  | 'soft__badge-red'
  | 'soft__badge-purple'
  | 'soft__badge-gray'
  | 'soft__badge-light-gray'
  | 'soft__badge-yellow'
  | 'soft__badge-green'
  // Disabled
  | 'disabled'
  | 'white-disabled'

export const colorVariantClasses: Record<ColorVariant, string> = {
  // ポテンシャルクラウドカラー
  'potential-cloud':
    'bg-gradient-to-r from-potential-blue-1 to-excellent-red-1 text-white hover:bg-gradient-to-r hover:from-[#4CAAD9] hover:to-[#ED6286]',

  // ベース
  'link-move': 'bg-knowledge-navy-1 text-white hover:bg-knowledge-navy-2',
  'link-potential': 'bg-potential-blue-1  text-white hover:bg-potential-blue-2',
  'link-excellent': 'bg-excellent-red-1 text-white hover:bg-excellent-red-2',
  'link-negative': 'bg-gray-2 text-white hover:bg-gray-3',
  success: 'bg-notification-true-1-2 text-white hover:bg-notification-true-1-3',
  alert: 'bg-notification-false-1-2 text-white hover:bg-notification-false-1-3',
  'soft__link-move':
    'bg-knowledge-navy-5 text-knowledge-navy-2 hover:bg-knowledge-navy-3 hover:text-white',
  'soft__link-potential':
    'bg-potential-blue-5 text-potential-blue-1 hover:bg-potential-blue-3 hover:text-white',
  'soft__link-excellent':
    'bg-excellent-red-5 text-excellent-red-1 hover:bg-excellent-red-3 hover:text-white',
  'soft__link-negative':
    'bg-gray-6 text-gray-2 hover:bg-gray-3 hover:text-white',
  soft__success:
    'bg-notification-true-1-5 text-notification-true-1-1 hover:bg-notification-true-1-3 hover:text-white',
  soft__alert:
    'bg-notification-false-1-5 text-notification-false-1-2 hover:bg-notification-false-1-3 hover:text-white',
  'outlined__link-move':
    'bg-white border border-solid border-knowledge-navy-1 text-knowledge-navy-1 hover:bg-knowledge-navy-5',
  'outlined__link-potential':
    'bg-white border border-solid border-potential-blue-1 text-potential-blue-1 hover:bg-potential-blue-5',
  'outlined__link-excellent':
    'bg-white border border-solid border-excellent-red-1 text-excellent-red-1 hover:bg-excellent-red-5',
  'outlined__link-negative':
    'bg-white border border-solid border-gray-2 text-gray-2 hover:bg-gray-6',
  outlined__success:
    'bg-white border border-solid border-notification-true-1-2 text-notification-true-1-2 hover:bg-notification-true-1-5',
  outlined__alert:
    'bg-white border border-solid border-notification-false-1-2 text-notification-false-1-2 hover:bg-notification-false-1-5',

  // サーベイステータス
  'survey-planning':
    'bg-potential-blue-5 text-potential-blue-1 hover:bg-potential-blue-4',
  'survey-prepared':
    'bg-excellent-red-5 text-excellent-red-1 hover:bg-excellent-red-4',
  'survey-progress': 'bg-excellent-red-1 text-white hover:bg-excellent-red-2',
  'survey-closed':
    'bg-notification-true-1-2 text-white hover:bg-notification-true-1-3',
  'survey-canceled': 'bg-gray-2 text-white hover:bg-gray-3',

  // バッジ ユニークカラー
  'badge-blue': 'bg-potential-blue-1 text-white',
  'badge-red': 'bg-excellent-red-1 text-white',
  'badge-purple': 'bg-evolve-purple-1 text-white',
  'badge-gray': 'bg-gray-2 text-white',
  'badge-light-gray': 'bg-gray-3 text-white',
  'badge-yellow': 'bg-notification-attention-1-2 text-white',
  'badge-green': 'bg-notification-true-1-2 text-white',
  'soft__badge-blue': 'bg-potential-blue-5 text-potential-blue-1',
  'soft__badge-red': 'bg-excellent-red-5 text-excellent-red-1',
  'soft__badge-purple': 'bg-evolve-purple-5 text-evolve-purple-1',
  'soft__badge-gray': 'bg-gray-5 text-gray-2',
  'soft__badge-light-gray': 'bg-gray-5 text-gray-3',
  'soft__badge-yellow':
    'bg-notification-attention-1-5 text-notification-attention-1-2',
  'soft__badge-green': 'bg-notification-true-1-5 text-notification-true-1-2',

  // Disabled
  disabled: 'bg-gray-6 text-gray-3 cursor-default pointer-events-none',
  'white-disabled':
    'bg-white text-gray-3 border border-solid border-gray-5 cursor-default pointer-events-none',
}
